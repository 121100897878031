import { IS_SSR } from 'ots-constants';
import { useEffect, useState } from 'react';

export const useReadyOnWebOnly = () => {
  const [ready, setReady] = useState<boolean>(false);
  useEffect(() => {
    if (!IS_SSR) {
      setReady(true);
    }
  }, []);
  return ready;
};
