import classNames from 'classnames';
import { OneTrustCategories } from 'onetrust/constants';
import { useRef } from 'react';
import { useOneTrustIframe } from 'util/useAddOneTrustIframe';
import styles from './styles.module.scss';

export const YouTubeEmbed = ({
  embedId,
  className,
}: {
  embedId: string,
  className?: string,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOneTrustIframe(ref?.current || null, `https://www.youtube.com/embed/${embedId}`, 'YouTube', OneTrustCategories.TargetingCookies, {
    class: styles.iframe,
    allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
    allowfullscreen: 'allowfullscreen',
    frameborder: '0',
  }, true);
  return (
    <div className={classNames(styles.playerBox, className)} ref={ref} />
  );
};
