import { IWithChildren } from 'types';
import classNames from 'classnames';
import { useNoHeader } from 'util/useNoHeader';
import styles from './styles.module.scss';

export const BottomMountains = (
  { className }: Partial<IWithChildren> & { className?: string },
) => {
  const { noHeader } = useNoHeader();
  if (noHeader) {
    return null;
  }
  return (
    <div className={classNames(styles.box, className)}>
      <div />
    </div>
  );
};
