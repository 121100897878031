import { YouTubeEmbed } from 'components/home/PlayerYouTube/YouTubeEmbed';
import classNames from 'classnames';
import { useReadyOnWebOnly } from 'util/useReadyOnWebOnly';
import styles from './styles.module.scss';

/**
 * @see https://stackoverflow.com/a/8260383
 */
export function youtubeParser(url: string) {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : false;
}

export const PlayerYouTube = ({
  urlYouTube,
  className,
}: {
  urlYouTube: string,
  className?: string,
}) => {
  const embedId = youtubeParser(urlYouTube);
  const ready = useReadyOnWebOnly();

  return (
    <div className={classNames(styles.player, className)}>
      {embedId && (
        <>
          {ready && (
          <YouTubeEmbed
            embedId={embedId}
          />
          )}
        </>
      )}
    </div>
  );
};
